<template>
<div class="footer">© Funscool LTD</div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Footer"
}
</script>

<style scoped>
.footer{
  width: 100%;
  height: 100px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #17B5BC;
  line-height: 100px;
}
</style>
