import {createStore} from "vuex";

export default

createStore({
    state () {
        return {
            mobile: false,
            header: {
                links: [
                    // {name: 'Дошкольное образование', link: ''},
                    // {name: 'Начальное образование', link: ''},
                    // {name: 'Средние образование', link: ''},
                    // {name: 'Профориентация', link: ''},
                    // {name: 'Робототехника', link: ''},
                    // {name: 'Арт', link: ''},
                    // {name: 'Танцы', link: ''},
                    // {name: 'Музыкальные инструменты', link: ''},
                    // {name: 'Вокал', link: ''},
                    // {name: 'Шахматы', link: ''},
                    // {name: 'Спортивные секции', link: ''},
                    // {name: 'Монтесори клуб', link: ''},
                    // {name: 'Сказкотерапия', link: ''},
                    // {name: 'Скорочтение', link: ''},
                ]
            },
            banners: [
                {src:"/banners/5.webp", msrc:"/banners/v5.webp",
                    header:"Добро пожаловать в FunsCool",
                    text:"FunsCool– образовательная среда с современной программой развития на базе международных стандартов образования.",
                    // bText:"",bLink:"tel:+35794089949",
                    bStyle:"classBannerFirst"},
                {src:"/banners/2.webp", msrc:"/banners/v6.webp",
                    header:"International Baccalaureate",
                    text:"Комплексное образование Вашего ребёнка от 1,5 до 7 лет по авторской русско-английской программе FunsCool, разработанной по системе IB.",
                    // bText:"Хочу знать больше",bLink:"tel:+35794089949",
                    bStyle:"classBannerSecond"},
                {src:"/banners/4.webp", msrc:"/banners/v4.webp",
                    header:"Мягкая адаптация",
                    text:"<ul style='padding-left: 0rem;font-size: 14px'><li>Дневник адаптации.</li><li>Правильный режим дня.</li><li>Прогулки 2 раза в день, здоровый сон, сбалансированное питание.</li><li>Переход из группы в группу в индивидуальном графике.</li><li>Теплая и радостная атмосфера в центре.</li><li>Дневник адаптации.</li><li>Правильный режим дня.</li></ul>",
                    // bText:"Подробнее",bLink:"tel:+35794089949",
                    bStyle:"classBannerThird"},
                {src:"/banners/1.webp", msrc:"/banners/v1.webp",
                    header:"Билингвальная программа",
                    text:"<ul style='padding-left: 0rem;font-size: 14px'><li>Соответствие ценностям системы IB International Baccalaureate.</li><li>Интеграция с основной образовательной программой «FunsCool».</li><li>Content and Language Integrated Learning или Предметно-Языковое Интегрированное Обучение.</li><li>Обучение на двух языках.</li></ul>",
                    // bText:"Проверить наличие мест",bLink:"tel:+35794089949",
                    bStyle:"classBannerFourth"},
                {src:"/banners/3.webp", msrc:"/banners/v3.webp",
                    header:"Дополнительные занятия «Skills»",
                    text:"<ul style='padding-left: 0rem;font-size: 14px'><li>Technic Skills</li><li>Art Skills</li><li>Mind Skills</li><li>Health Skills</li><li>Language skills</li><li>Social Skills</li><li>Science Skills</li></ul>",
                    // bText:"Записаться на пробное занятие",
                    bLink:"tel:+35794089949",
                    bStyle:"classBannerFifth"},
                // {src:"/banners/6.webp", msrc:"/banners/v6.webp",
                //     header:"",
                //     text:"Мы будем вести Вашего ребенка шаг за шагом от детского сада до выбора своего жизненного призвания по современным стандартам и последним технологиям для достижения уровня жизни высокого качества благодаря личной самореализации, счастью и успеху.",
                //     // bText:"Позвонить",
                //     bLink:"tel:+35794089949",
                //     bStyle:"classBannerFirst"},
            ],
            team:[
                {department:"Состав воспитателей",
                    mainPhoto:"/team/hr0.webp",
                    mobile_mainPhoto:"/team/vhr0.webp",
                team:[
                    {name:'Иванова Ивана Ивановни', photo:'/team/hr1.webp', description:"Учитель тысячителятия, носитель 48 языков, препод от природы"},
                    {name:'Иванова Ивана Ивановни', photo:'/team/hr2.webp', description:"Учитель тысячителятия, носитель 48 языков, препод от природы"},
                    {name:'Иванова Ивана Ивановни', photo:'/team/hr3.webp', description:"Учитель тысячителятия, носитель 48 языков, препод от природы"},
                    {name:'Иванова Ивана Ивановни', photo:'/team/hr4.webp', description:"Учитель тысячителятия, носитель 48 языков, препод от природы"},
                    {name:'Иванова Ивана Ивановни', photo:'/team/hr5.webp', description:"Учитель тысячителятия, носитель 48 языков, препод от природы"},
                    {name:'Иванова Ивана Ивановни', photo:'/team/hr6.webp', description:"Учитель тысячителятия, носитель 48 языков, препод от природы"},
                ]},
                // {department:"Кафедра русского языка",
                //     team:[
                //         {name:'Иванова Ивана Ивановни', photo:'/team/iv.webp', description:"Учитель тысячителятия, носитель 48 языков, препод от природы"},
                //         {name:'Иванова Ивана Ивановни', photo:'/team/iv.webp', description:"Учитель тысячителятия, носитель 48 языков, препод от природы"},
                //         {name:'Иванова Ивана Ивановни', photo:'/team/iv.webp', description:"Учитель тысячителятия, носитель 48 языков, препод от природы"},
                //         {name:'Иванова Ивана Ивановни', photo:'/team/iv.webp', description:"Учитель тысячителятия, носитель 48 языков, препод от природы"},
                //         {name:'Иванова Ивана Ивановни', photo:'/team/iv.webp', description:"Учитель тысячителятия, носитель 48 языков, препод от природы"},
                //         {name:'Иванова Ивана Ивановни', photo:'/team/iv.webp', description:"Учитель тысячителятия, носитель 48 языков, препод от природы"},
                //     ]},
                // {department:"Кафедра физической культуры",
                //     team:[
                //         {name:'Иванова Ивана Ивановни', photo:'/team/iv.webp', description:"Учитель тысячителятия, носитель 48 языков, препод от природы"},
                //         {name:'Иванова Ивана Ивановни', photo:'/team/iv.webp', description:"Учитель тысячителятия, носитель 48 языков, препод от природы"},
                //         {name:'Иванова Ивана Ивановни', photo:'/team/iv.webp', description:"Учитель тысячителятия, носитель 48 языков, препод от природы"},
                //         {name:'Иванова Ивана Ивановни', photo:'/team/iv.webp', description:"Учитель тысячителятия, носитель 48 языков, препод от природы"},
                //         {name:'Иванова Ивана Ивановни', photo:'/team/iv.webp', description:"Учитель тысячителятия, носитель 48 языков, препод от природы"},
                //         {name:'Иванова Ивана Ивановни', photo:'/team/iv.webp', description:"Учитель тысячителятия, носитель 48 языков, препод от природы"},
                //     ]},
            ],

            contacts:{
                "tel":"+35794089949"
            }
        }
    },
    mutations: {
        mobile(state, isMobile){
            state.mobile = isMobile;
        }
    }
})
