import  "./colors.scss"
import "bootstrap"


import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import { createWebHistory, createRouter } from 'vue-router'
const routes = [
    { path: '/', component: ()=>import('./pages/Home.vue') },
    { path: '/Team', component: ()=>import('./pages/Team.vue') },
    // { path: '/about', component: ()=> import('./components/HelloWorld.vue') },
]
const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0 }
        }
    },
})
if (document.body.clientWidth < 650) {
    document.body.classList.add('mobile');
    store.commit('mobile', true);
}

createApp(App)
    .use(router)
    .use(store)
    .mount('#app')
