<template>
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid ">
      <a class="navbar-brand" href="/"><img src="../assets/logo.png" style="width: 150px; margin: 10px"></a>

      <div class="collapse navbar-collapse" style="margin-right: 20px">
        <ul class="navbar-nav ms-auto me-0">
          <li class="nav-item"
              v-for="i in $store.state.header.links.length>4?4:$store.state.header.links.length"
              :key="i"
          >
            <a class="nav-link active" aria-current="page"
               :href="$store.state.header.links[i-1].link">
              {{$store.state.header.links[i-1].name}}
            </a>
          </li>
          <li class="nav-item dropdown" v-if="$store.state.header.links.length>4">
            <a class="nav-link active dropdown-toggle"
               href="#"
               id="navbarDropdown"
               role="button"
               data-bs-toggle="dropdown"
               aria-expanded="false"
            >
              Еще
            </a>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
              <li v-for="i in $store.state.header.links.length - 4"
                  :key="i"
              >
                <a class="dropdown-item" :href="$store.state.header.links[i+3].link">{{ $store.state.header.links[i+3].name }}</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>


<!--      <div style="color: #17B5BC; font-weight: 500; text-decoration: underline">En</div>-->

<!--      <div class="dropdown">-->
<!--        <button-->
<!--            class="btn d-lg-none d-xl-none d-xxl-none"-->
<!--            type="button"-->
<!--            id="dropdownMenuButton1"-->
<!--            data-bs-toggle="dropdown"-->
<!--            aria-expanded="false"-->
<!--        >-->
<!--          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <rect width="48" height="48" fill="white" fill-opacity="0.01"/>-->
<!--            <path d="M9 14.9498H39.9498" stroke="#17B5BC" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--            <path d="M9 23.9498H39.9498" stroke="#17B5BC" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--            <path d="M9 32.9498H39.9498" stroke="#17B5BC" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--          </svg>-->
<!--        </button>-->
<!--        <ul class="dropdown-menu dropdown-menu-end " aria-labelledby="dropdownMenuButton1">-->
<!--          <li v-for="link in $store.state.header.links" :key="link.name">-->
<!--            <a class="dropdown-item" :href="link.link">{{ link.name }}</a></li>-->
<!--        </ul>-->
<!--      </div>-->
    </div>
    <a href="tel:+35794089949" class="phoneCall">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px" height="20px" viewBox="0 0 20 20" version="1.1" style="margin-bottom:5px;">
        <g id="surface1">
          <path style="fill:none;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke:#17B5BC;stroke-opacity:1;stroke-miterlimit:10;" d="M 30.214844 35.253906 C 30.214844 35.253906 34.267578 32.929688 35.322266 32.373047 C 36.386719 31.816406 37.480469 31.679688 38.144531 32.080078 C 39.150391 32.695312 47.607422 38.320312 48.320312 38.818359 C 49.033203 39.316406 49.375 40.742188 48.398438 42.138672 C 47.421875 43.535156 42.919922 49.0625 41.015625 48.994141 C 39.101562 48.935547 31.171875 48.759766 16.201172 33.789062 C 1.240234 18.828125 1.064453 10.888672 0.996094 8.974609 C 0.9375 7.070312 6.455078 2.568359 7.851562 1.591797 C 9.257812 0.615234 10.683594 0.986328 11.171875 1.669922 C 11.738281 2.451172 17.304688 10.878906 17.910156 11.845703 C 18.330078 12.509766 18.173828 13.613281 17.617188 14.667969 C 17.060547 15.732422 14.746094 19.775391 14.746094 19.775391 C 14.746094 19.775391 16.376953 22.568359 21.904297 28.085938 C 27.431641 33.613281 30.214844 35.253906 30.214844 35.253906 Z M 30.214844 35.253906 " transform="matrix(0.4,0,0,0.4,0,0)"/>
        </g>
      </svg>
      94 08 9949
    </a>
  </nav>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Header",
}
</script>

<style scoped lang="scss">
.navbar{position: absolute; z-index: 5}
.navbar-brand{margin-left: 30px}
.dropdown-item{
  color: #17B5BC;
  font-weight: 600;
  line-height: 38px;
  font-size: 22px;
}
.nav-item{
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  .dropdown-item{
    line-height: 20px;
    font-size: 16px;
  }
  a{
    color: #17B5BC !important;
    font-weight: 600;
  }
}
.phoneCall{
  position: fixed;
  right: 10px;
  cursor: pointer;
  background-color: white;
  height: 40px;
  line-height: 40px;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  font-size: 20px;
  padding: 0px 20px;
  border: lightgoldenrodyellow 0.5px solid;
  border-radius: 20px;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.mobile{
  .navbar-brand{
    margin-left: 0px
  }
}
</style>
