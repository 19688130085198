<template>
  <Header/>
  <router-view></router-view>
  <Footer/>
</template>

<script>
import Footer from "@/components/Footer";
import Header from "@/components/Header";

export default {
  name: 'App',
  components: {Header, Footer},
}
</script>

<style>
.staticPage{
  max-width: 900px;
  margin: 10px auto;
  padding-top: 90px;
}

</style>
